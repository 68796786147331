import logo from './assets/uba_kurumsal_logo_buyuk.svg';
import logoIcon from './assets/uba_kurumsal_logo.svg';
import webGiris from './assets/web_giris.png';
import './assets/bootstrap.min.css';
import './assets/app.scss';

function App() {
    return (
        <>
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col text-center">
                            <img src={logo} className="brand-logo" alt=""/>
                        </div>
                    </div>
                </div>
            </header>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-2"></div>
                    <div className="col-md-4">
                        <img src={webGiris} className="img-fluid" alt=""/>
                    </div>
                    <div className="col-md-4">
                        <p className="mt-4"><strong>"Uzaylı Bir Anne"</strong> adıyla Eylül 2019’dan beri kadın, anne &
                            bebek, güzellik, alışveriş moda ve
                            yaşam üzerine
                            yazıyor, konuşuyorum.</p>
                        <p>Amacım, her kategoride ürünleri ve annelik serüvenimdeki tüm tecrübelerimi kadınlarla şeffaf
                            bir şekilde
                            paylaşmak, bunlardan faydalanmalarını sağlamak. Hem kadın, hem anne olarak her yaş grubuna
                            hitap
                            edebilmek ve herkesten bir parça barındırmak aslında.</p>
                        <p>Ne duruyorsun hadi, sende bize katıl...</p>
                        <h1 className="signature">G. Güçlükol</h1>
                    </div>
                </div>
            </div>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col text-center">
                            <img src={logoIcon} alt="" className="mb-4"/>
                            <p className="m-0 contact">E-Mail : <a
                                href="mailto:hi@uzaylibiranne.com">hi@uzaylibiranne.com</a></p>
                            <p className="mt-3 mb-0 copyright-t">© 2021. Tüm Hakları Saklıdır.</p>
                            <p className="copyright-b">Sitede bulunan tüm görsel ve yazılı materyaller izinli/izinsiz
                                hiçbir şekilde kullanılamaz.</p>
                            <a className="instagram-button" href="https://www.instagram.com/uzaylibiranne/"
                               title="Uzaylı Bir Anne Instagram">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height={25}>
                                    <path
                                        d="M510.46 150.453c-1.246-27.25-5.574-45.86-11.902-62.14-6.426-17.082-16.504-32.555-29.527-45.344-12.785-13.023-28.262-23.105-45.344-29.535-16.285-6.324-34.89-10.648-62.14-11.887C334.246.297 325.523 0 256 0s-78.246.297-105.547 1.54c-27.25 1.246-45.855 5.574-62.14 11.902C71.23 19.867 55.758 29.945 42.97 42.97a125.49 125.49 0 0 0-29.535 45.34C7.11 104.594 2.785 123.203 1.547 150.45.297 177.754 0 186.473 0 255.996l1.547 105.55c1.242 27.246 5.57 45.855 11.898 62.14a125.45 125.45 0 0 0 29.527 45.34c12.785 13.023 28.262 23.102 45.344 29.527 16.28 6.332 34.89 10.656 62.14 11.902 27.305 1.246 36.023 1.54 105.547 1.54s78.246-.293 105.547-1.54c27.25-1.246 45.855-5.57 62.14-11.902a130.88 130.88 0 0 0 74.867-74.867c6.332-16.285 10.656-34.895 11.902-62.14C511.703 334.242 512 325.523 512 256l-1.54-105.547zM464.38 359.45c-1.137 24.96-5.31 38.516-8.812 47.535-8.613 22.328-26.258 39.973-48.586 48.586-9.02 3.504-22.574 7.676-47.535 8.813-26.988 1.234-35.086 1.492-103.445 1.492s-76.457-.258-103.45-1.492c-24.957-1.137-38.512-5.31-47.535-8.812-11.117-4.105-21.176-10.648-29.434-19.152-8.504-8.258-15.047-18.312-19.152-29.434-3.504-9.02-7.676-22.574-8.812-47.535-1.23-26.992-1.492-35.1-1.492-103.445l1.492-103.45c1.14-24.96 5.31-38.516 8.813-47.535 4.105-11.12 10.652-21.18 19.152-29.437a79.26 79.26 0 0 1 29.438-19.148c9.02-3.508 22.574-7.676 47.535-8.816 26.992-1.23 35.1-1.492 103.445-1.492h-.004l103.45 1.496c24.96 1.137 38.512 5.31 47.535 8.813 11.117 4.105 21.176 10.648 29.434 19.148a79.26 79.26 0 0 1 19.148 29.438c3.508 9.02 7.68 22.574 8.816 47.535 1.23 26.992 1.492 35.1 1.492 103.445l-1.492 103.45zm0 0"
                                        fill="currentColor"/>
                                    <path
                                        d="M255.996 124.54c-72.602 0-131.457 58.86-131.457 131.46s58.855 131.457 131.457 131.457S387.457 328.602 387.457 256s-58.855-131.46-131.46-131.46zm0 216.793c-47.125-.004-85.332-38.207-85.328-85.336S208.87 170.664 256 170.664s85.332 38.207 85.332 85.332-38.207 85.336-85.336 85.336zm0 0"
                                        fill="currentColor"/>
                                    <path
                                        d="M423.37 119.348c0 16.965-13.754 30.72-30.72 30.72s-30.723-13.754-30.723-30.72 13.754-30.723 30.723-30.723 30.72 13.754 30.72 30.723zm0 0"
                                        fill="currentColor"/>
                                </svg>
                                @uzaylibiranne
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </>

    );
}

export default App;
